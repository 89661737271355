import { Fragment, useEffect, useState, useRef, createContext } from "react";
import ModalHeader from "./scenario_modal_stepper/ModalHeader";
import "./NewScenarioFlow.scss";
import ScenarioDetails from "./scenario_modal_stepper/ScenarioDetails";
import OrbitalDetails from "./scenario_modal_stepper/OrbitalDetails";
import { useNavigate, useParams } from "react-router-dom";
import AdditionaOrbitDetails from "./scenario_modal_stepper/externalOrbits/AdditionaOrbitDetails";
import ScenarioSchedule from "./scenario_modal_stepper/ScenarioSchedule";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ScenarioGroundStation from "./scenario_modal_stepper/ScenarioGroundStation";
import MissionService from "../../../../../service/MissionService";
import CommandCenterService from "../../../../../service/CommandCenterService";
import CatalogueService from "../../../../../service/CatalogueService";
import { capitalizeFirstLetter, navigateTo, truncateNumber } from "../../../../CommonComponents/CommonFunctions";
import VisualizationWrapper from "./scenario_visualization/VisualizationWrapper";
import ScenarioScheduleModal from "./modal/ScenarioScheduleModal";
import { ToastContainer, toast } from "react-toastify";
import LoaderAnimation from "../../Loader/LoaderAnimation";
import GroundStationService from "../../../../../service/GroundStationService";
import { CircularProgress } from "@mui/material";
import RightHeaderSection from "./scenario_visualization/right_header_section/RightHeaderSection";
import PowerGraphModal from "./modal/PowerGraphModal";
import ADCSGraphModal from "./modal/ADCSGraphModal";
import DataFlowGraphModal from "./modal/DataFlowGraphModal";
import { scenario_visualization } from "./scenario_visualization/DataSources";

import * as Cesium from "cesium";
dayjs.extend(utc)

interface ViewerContextType {
  viewer: Cesium.Viewer | null;
  setViewer: (data: any) => void;
}
export const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

type ImportedScheduletype = {
  file_data: any,
  name: '',
  schedule_upload: boolean,
  schedule_imported: boolean,
  show_upload_btn: boolean,
  show_deploy_btn: boolean,
}

const NewScenarioFlow = () => {
  const params = useParams();
  const {
    mission_name,
    mission_id,
    sat_name,
    sat_id,
    truetwin_name,
    truetwin_id,
    scenario_name,
    scenario_id,
    scenario_step
  } = params;

  const navigate = useNavigate()
  const modalRef = useRef(false)
  const scrollRef = useRef(null)
  const formSectionRef = useRef(null)
  const [openTaskPlanMenu, setOpenTaskPlanMenu] = useState(false)
  const [userData, setUserData] = useState<any>({});
  const [viewer, setViewer] = useState(null)

  const [minimize_modal, set_minimize_modal] = useState(true);
  const [modalDetails, setModalDetails] = useState({
    'power': {
      open: false,
      pinned: false,
      current_modal: false,
      expanded: false,
      zIndex: 99
    },
    'adcs': {
      open: false,
      pinned: false,
      current_modal: false,
      expanded: false,
      zIndex: 99
    },
    'data_flow': {
      open: false,
      pinned: false,
      current_modal: false,
      expanded: false,
      zIndex: 99
    },
    'schedule': {
      open: false,
      pinned: false,
      current_modal: false,
      expanded: false,
      zIndex: 99
    },
  });
  const [deployLoader, setDeployLoader] = useState<any>(false);

  const [addAdditionaOrbitDetails, setAddAdditionaOrbitDetails] = useState<any>(false);
  const [dataSubmited, setDataSubmited] = useState<any>([]);
  const [scenarioScheduleData, setScenarioScheduleData] = useState<any>([]);
  const [Template, setTemplate] = useState<any>({});
  const [Mission, setMission] = useState<any>({});
  const [MissionGSList, setMissionGSList] = useState<any>([]);
  const [TrueTwin, setTrueTwin] = useState<any>({});
  const [loading, setLoading] = useState<boolean | string>(false)
  const [prediction_loading, set_prediction_loading] = useState<boolean | string>(false)
  const [scenarioResponseData, setScenarioResponseData] = useState<any>({})
  const [uploadFileStatus, setUploaddFileStatus] = useState<any>()
  const [orbit_launch_details, set_orbit_launch_details] = useState<any>({
    epoch: '',
    altitude: "",
    eccentricity: "",
    inclination: "",
    RAAN: "",
    AP: "",
    TA: ""
  })
  const [importedSchedule, setImportedSchedule] = useState<ImportedScheduletype>({
    file_data: null,
    name: '',
    schedule_upload: false,
    schedule_imported: false,
    show_upload_btn: false,
    show_deploy_btn: false
  })

  const [simulationTime, setSimulationTime] = useState<{
    start_time: string | number,
    end_time: string | number,
  }>({
    start_time: '',
    end_time: '',
  });

  const [show_schedule_notification, set_show_schedule_notification] = useState<boolean>(false)

  const [scenarioData, setScenarioData] = useState<any>({
    scenario_details: {
      scenarioName: "",
      sampleRate: 1,
      draftMode: 'Yes',
    },
    orbit_details: {
      epoch: '',
      altitude: "",
      eccentricity: "",
      inclination: "",
      RAAN: "",
      AP: "",
      TA: ""
    },
    additional_orbits: [],
    additional_orbit_details: {
      name: "",
      altitude: "",
      eccentricity: "",
      inclination: "",
      RAAN: "",
      AP: "",
      TA: ""
    },
    ground_stations: [],
    task_plan: '',
    start_time: '',
    duration: 180,
  })

  const [prediction_data, set_prediction_data] = useState<{
    adcs_urls: any[],
    data_flow_urls: any[],
    power_urls: any[],
    has_prediction_data: boolean,
  }>({
    adcs_urls: [],
    data_flow_urls: [],
    power_urls: [],
    has_prediction_data: false,
  })

  const [additionalObjectUpdated, setAdditionalObjectUpdated] = useState<boolean>(false)


  const get_max_zIndex = () => {
    let max_Z = 0;
    max_Z = Object.values(modalDetails).reduce((maxZIndex, panel) => {
      return panel.zIndex > maxZIndex ? panel.zIndex : maxZIndex;
    }, 0);
    return max_Z + 1
  }

  const get_previous_current_modal = (closed_modal: string) => {
    return Object.entries(modalDetails).reduce((maxPanel, [key, panel]) => {
      return (panel.zIndex > maxPanel.zIndex && panel.open && closed_modal !== key) ? { name: key, zIndex: panel.zIndex } : maxPanel;
    }, { name: '', zIndex: 0 });
  }

  const open_modal = (modal_name: string) => {
    const current_modal_zIndex = get_max_zIndex()
    Object.keys(modalDetails).map((key: any) => {
      if (key === modal_name) {
        setModalDetails((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded, zIndex: current_modal_zIndex } }))
      } else {
        setModalDetails((prev) => ({ ...prev, [key]: { open: prev[key].pinned ? true : false, pinned: prev[key].pinned, current_modal: false, expanded: (prev[key].pinned && prev[key].expanded) ? prev[key].expanded : false, zIndex: prev[key].zIndex } }))
      }
    })
  };

  const set_top_modal = (modal_name: string) => {
    const current_modal_zIndex = get_max_zIndex()
    Object.keys(modalDetails).map((key: any) => {
      if (key === modal_name) {
        setModalDetails((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded, zIndex: modalDetails[modal_name].current_modal !== modal_name ? current_modal_zIndex : prev[key].zIndex } }))
      } else {
        setModalDetails((prev) => ({ ...prev, [key]: { open: prev[key].open ? true : false, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
      }
    })
  };

  const set_expanded = (modal_name: string) => {
    Object.keys(modalDetails).map((key: any) => {
      if (key === modal_name) {
        setModalDetails((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? true : false, current_modal: modal_name, expanded: prev[key].expanded ? false : modal_name, zIndex: prev[key].zIndex } }))
      } else {
        setModalDetails((prev) => ({ ...prev, [key]: { open: prev[key].open, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
      }
    })
  };

  const close_modal = (modal_name: string) => {
    const previous_modal = get_previous_current_modal(modal_name)
    setModalDetails((prev) => (
      {
        ...prev,
        [modal_name]: { open: false, pinned: false, current_modal: false, expanded: false, zIndex: 99 },
        [previous_modal.name]: { ...modalDetails[previous_modal.name], current_modal: previous_modal.name }
      }))
  };

  const pinned_modal = (modal_name: string) => {
    Object.keys(modalDetails).map((key: string) => {
      if (key === modal_name) {
        setModalDetails((prev) => ({ ...prev, [key]: { open: true, pinned: prev[key].pinned ? false : true, current_modal: modal_name, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
      } else {
        setModalDetails((prev) => ({ ...prev, [key]: { open: prev[key].open, pinned: prev[key].pinned, current_modal: false, expanded: prev[key].expanded, zIndex: prev[key].zIndex } }))
      }
    })
  };

  // we can use this function to close all unpinned modals later
  const close_unpinned_modal = () => {
    if (modalRef.current) {
      modalRef.current = false
      return
    }
    Object.keys(modalDetails).forEach((key) => {
      if (!modalDetails[key].pinned) {
        setModalDetails((prev) => ({ ...prev, [key]: { open: false, pinned: false, current_modal: false, expanded: false, zIndex: 99 } }))
      }
    })
  }

  const navigate_to_truetwin_details = () => {
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${truetwin_name}/${truetwin_id}/Scenarios`,
      navigate
    );
  }

  const navigate_to_tt_dashboard = () => {
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/dashboard/${scenario_name}/${scenario_id}`,
      navigate
    );
  };

  const navigate_scenario = (step: string, scenarioName?: string, scenarioId?: string) => {
    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/update_scenario/${scenarioName}/${scenarioId}/${step}`);
  }

  const reset_data = () => {
    setScenarioScheduleData([])
    setImportedSchedule({
      ...importedSchedule,
      schedule_upload: false,
      show_upload_btn: importedSchedule?.['schedule_imported'],
      show_deploy_btn: false,
    })
    set_prediction_data({
      adcs_urls: [],
      data_flow_urls: [],
      power_urls: [],
      has_prediction_data: false,
    })
    Object.keys(modalDetails).forEach((key) => {
      setModalDetails((prev) => ({ ...prev, [key]: { open: false, pinned: false, current_modal: false, expanded: false } }))
    })
  }


  useEffect(() => {
    if (sessionStorage.getItem("userDetails")) {
      let user = JSON.parse(sessionStorage.getItem("userDetails") || "");
      setUserData(user);
    }
  }, [])

  const get_latest_orbit_details = () => {
    MissionService.getLatestOrbitDetails(sat_id, 'Launch').then((res) => {
      if (res.data) {
        const data = res.data
        const orbit_data = {
          'altitude': data?.['OrbitParameters']?.['altitude'] ? data?.['OrbitParameters']?.['altitude'] : '',
          'eccentricity': data?.['OrbitParameters']?.['eccentricity'] ? data?.['OrbitParameters']?.['eccentricity'] : '',
          'inclination': data?.['OrbitParameters']?.['inclination'] ? data?.['OrbitParameters']?.['inclination'] : '',
          'AP': data?.['OrbitParameters']?.['AP'] ? data?.['OrbitParameters']?.['AP'] : '',
          'TA': data?.['OrbitParameters']?.['TA'] ? data?.['OrbitParameters']?.['TA'] : '',
          'RAAN': data?.['OrbitParameters']?.['RAAN'] ? data?.['OrbitParameters']?.['RAAN'] : '',
          'epoch': data?.['OrbitParameters']?.['epoch'] ? data?.['OrbitParameters']?.['epoch'] * 1000 : ''
        }
        set_orbit_launch_details(orbit_data)
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const check_current_step = () => {
    if (scenario_id && scenario_id !== undefined) {
      MissionService.getScenarioById(scenario_id).then((res) => {
        if (res?.data) {
          let response = res?.data
          let scenario_gs: string[] = []
          if (response?.groundStations) {
            response.groundStations.map(st => {
              if (scenarioData.ground_stations.includes(st)) {
                scenario_gs.push(st)
              }
            })
          }
          scenario_visualization().init_ground_station_packet(viewer, scenario_gs, MissionGSList)
          let scenario_data = {
            scenario_details: {
              scenarioName: response?.scenarioName ? response?.scenarioName : scenarioData.scenario_details.scenarioName,
              sampleRate: response?.sampleRate ? response?.sampleRate : scenarioData.scenario_details.sampleRate,
              draftMode: response?.draftMode ? response?.draftMode : scenarioData.scenario_details.draftMode,
            },
            orbit_details: {
              altitude: (response?.orbit && 'altitude' in response?.orbit) ? response?.orbit?.altitude : scenarioData.orbit_details.altitude,
              eccentricity: (response?.orbit && 'eccentricity' in response?.orbit) ? response?.orbit?.eccentricity : scenarioData.orbit_details.eccentricity,
              inclination: (response?.orbit && 'inclination' in response?.orbit) ? response?.orbit?.inclination : scenarioData.orbit_details.inclination,
              RAAN: (response?.orbit && 'RAAN' in response?.orbit) ? response?.orbit?.RAAN : scenarioData.orbit_details.RAAN,
              AP: (response?.orbit && 'AP' in response?.orbit) ? response?.orbit?.AP : scenarioData.orbit_details.AP,
              TA: (response?.orbit && 'TA' in response?.orbit) ? response?.orbit?.TA : scenarioData.orbit_details.TA,
              epoch: (response?.orbit && 'epoch' in response?.orbit) ? (response?.orbit?.epoch * 1000) : scenarioData.orbit_details.epoch,
            },
            additional_orbits: (response?.externalAttachedOrbits && response?.['externalAttachedOrbits']?.length > 0) ? response?.externalAttachedOrbits : [],
            additional_orbit_details: scenarioData?.additional_orbit_details,
            ground_stations: response?.groundStations ? scenario_gs : scenarioData.ground_stations,
            task_plan: response?.taskPlanId ? response?.taskPlanId : scenarioData.task_plan,
            start_time: response?.simulationStartTime ? (response?.simulationStartTime * 1000) : scenarioData.start_time,
            duration: response?.simulationDuration ? response?.simulationDuration : scenarioData.duration,
          }
          scenario_visualization().init_aoi_packet(viewer, scenario_data?.task_plan)
          setScenarioResponseData(response)
          if (scenario_step === "Scenario_Details" || scenario_step === "Ground_Station" || scenario_step === "Schedule") {
            let simulation_start_time = response?.simulationStartTime ? (response?.simulationStartTime * 1000) : new Date(scenarioData.start_time).getTime()
            let simulation_duration = response?.simulationDuration ? response?.simulationDuration : scenarioData.duration
            set_simulation_time(simulation_start_time, simulation_duration)
          }
          if (response?.['scenarioName']) {
            if (response?.orbit) {
              if (response?.physicalCharacteristics?.mass !== '') {
                if (response?.externalAttachedOrbits && response?.externalAttachedOrbits?.length > 0) {
                  if (response?.groundStations && response?.groundStations?.length > 0) {
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
                  } else {
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits'])
                  }
                  setScenarioData(scenario_data)
                } else {
                  calculate_orbital_details(scenario_data)
                  if (scenario_gs?.length > 0) {
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
                  } else {
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits'])
                  }
                }
              } else {
                setDataSubmited(['Scenario_Details', 'Orbital_Details'])
              }
              scenario_visualization().init_satellite_packet(viewer, scenario_id, truetwin_id)
            } else {
              calculate_orbital_details(scenario_data)
              setDataSubmited(['Scenario_Details'])
            }
          }
        }
      })?.catch((err) => {
        console.log(err);
        toast.error('Error in getting scenario details.', { toastId: '0' });
      }).finally(() => {
        setLoading(false)
      })
    }
  }


  const get_scenario_data = (scenarioId) => {
    MissionService.getScenarioById(scenarioId).then((res) => {
      if (res?.data) {
        setScenarioResponseData(res?.data)
        let simulation_start_time = res?.data?.['simulationStartTime'] * 1000
        let simulation_duration = res?.data?.['simulationDuration']
        const additional_orbits = (res?.data?.externalAttachedOrbits && res?.data?.['externalAttachedOrbits']?.length > 0) ? res?.data?.externalAttachedOrbits : []

        setScenarioData({
          ...scenarioData,
          scenario_details: {
            ...scenarioData?.['scenario_details'],
            scenarioName: res?.data?.scenarioName,
          },
          additional_orbits: additional_orbits,
          start_time: simulation_start_time,
          duration: simulation_duration
        })
        set_simulation_time(simulation_start_time, simulation_duration)
        if (scenario_step === "Schedule") {
          setScenarioScheduleData((res?.data?.taskSchedule && res?.data?.taskSchedule?.length > 0) ? res?.data?.taskSchedule : [])
          setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station', 'Schedule'])
        }
      }
    })?.catch((err) => {
      console.log(err);
      toast.error('Error in getting scenario details.', { toastId: '0' });
    }).finally(() => {
      setLoading(false)
    })
  }


  const get_global_groundstations = (mission_gs_list) => {
    GroundStationService.getGroundStationsByMissionId()?.then((res) => {
      if (res.data) {
        let gs_list = res?.['data'].filter(gs => mission_gs_list.includes(gs?.['groundStationId']))
        setMissionGSList(gs_list)
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  const get_mission_details = () => {
    if (mission_id && mission_id !== undefined) {
      MissionService.get_mission_by_id(mission_id)?.then((res) => {
        if (res.data) {
          setMission(res?.data);
          get_satellite();
          if (res?.['data']?.['gsList'] && res?.['data']?.['gsList']?.length > 0) {
            let gs_list = res?.['data']?.['gsList']
            get_global_groundstations(gs_list)
            setScenarioData((prev) => ({ ...prev, ground_stations: gs_list }))
          } else {
            setLoading(false);
          }
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false);
      })
    }
  }

  const get_satellite = () => {
    if (sat_id && sat_id !== undefined) {
      MissionService.get_satellite_by_sat_id(sat_id)
        ?.then((sat) => {
          if (sat.data) {
            get_bus_template(sat.data?.["versions"]?.[0]?.["assembly"]?.["Template"])
            get_truetwin_details();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const get_bus_template = (tempId) => {
    CatalogueService.getTemplate(tempId)?.then((temp) => {
      if (temp?.data) {
        setTemplate(temp?.data);
      }
    })?.catch((err) => {
      console.error(err);
    })
  }

  const get_truetwin_details = () => {
    CommandCenterService.getTrueTwinStatus(truetwin_id)
      ?.then((res) => {
        if (res.data) {
          setTrueTwin(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (sat_id) {
      get_latest_orbit_details();
    }
  }, [sat_id]);

  useEffect(() => {
    if (mission_id) {
      get_mission_details();
    }
  }, [mission_id]);

  useEffect(() => {
    if (Object.keys(Template)?.length > 0 && scenario_id) {
      check_current_step()
    }
  }, [Template, scenario_id])


  const calculate_orbital_details = (scenario_data) => {
    if (scenario_step === "Scenario_Details" || scenario_step === "Orbital_Details") {
      let simulation_start_time = scenario_data?.start_time !== '' ? new Date(scenario_data?.start_time).getTime() : new Date().getTime()
      let simulation_duration = scenario_data?.['simulationDuration'] ? scenario_data?.['simulationDuration'] : 180
      setScenarioData({
        ...scenario_data,
        start_time: scenario_data?.start_time !== '' ? scenario_data?.start_time : new Date().getTime(),
        orbit_details: {
          epoch: scenario_data?.orbit_details?.epoch !== '' ? scenario_data?.orbit_details?.epoch : orbit_launch_details.epoch ? orbit_launch_details.epoch : new Date().getTime(),
          altitude: scenario_data?.orbit_details?.altitude !== '' ? scenario_data?.orbit_details?.altitude : orbit_launch_details.altitude ? orbit_launch_details.altitude : Mission?.['mission-attributes']?.['Altitude_range']?.['max'],
          inclination: scenario_data?.orbit_details?.inclination !== '' ? scenario_data?.orbit_details?.inclination : orbit_launch_details.inclination ? orbit_launch_details.inclination : Mission?.['mission-attributes']?.['inclination_angle'],
          eccentricity: scenario_data?.orbit_details?.eccentricity !== '' ? scenario_data?.orbit_details?.eccentricity : orbit_launch_details.eccentricity ? orbit_launch_details.eccentricity : 0,
          RAAN: scenario_data?.orbit_details?.RAAN !== '' ? scenario_data?.orbit_details?.RAAN : orbit_launch_details.RAAN ? orbit_launch_details.RAAN : 0,
          AP: scenario_data?.orbit_details?.AP !== '' ? scenario_data?.orbit_details?.AP : orbit_launch_details.AP ? orbit_launch_details.AP : 0,
          TA: scenario_data?.orbit_details?.TA !== '' ? scenario_data?.orbit_details?.TA : orbit_launch_details.TA ? orbit_launch_details.TA : 0
        }
      })
      set_simulation_time(simulation_start_time, simulation_duration)
    } else {
      setScenarioData({
        ...scenario_data,
        additional_orbit_details: {
          name: '',
          altitude: scenario_data?.orbit_details?.altitude !== '' ? scenario_data?.orbit_details?.altitude : orbit_launch_details.altitude ? orbit_launch_details.altitude : Mission?.['mission-attributes']?.['Altitude_range']?.['max'],
          inclination: scenario_data?.orbit_details?.inclination !== '' ? scenario_data?.orbit_details?.inclination : orbit_launch_details.inclination ? orbit_launch_details.inclination : Mission?.['mission-attributes']?.['inclination_angle'],
          eccentricity: scenario_data?.orbit_details?.eccentricity !== '' ? scenario_data?.orbit_details?.eccentricity : orbit_launch_details.eccentricity ? orbit_launch_details.eccentricity : 0,
          RAAN: scenario_data?.orbit_details?.RAAN !== '' ? scenario_data?.orbit_details?.RAAN : orbit_launch_details.RAAN ? orbit_launch_details.RAAN : 0,
          AP: scenario_data?.orbit_details?.AP !== '' ? scenario_data?.orbit_details?.AP : orbit_launch_details.AP ? orbit_launch_details.AP : 0,
          TA: scenario_data?.orbit_details?.TA !== '' ? scenario_data?.orbit_details?.TA : orbit_launch_details.TA ? orbit_launch_details.TA : 0
        }
      })
    }
  }


  const check_validation = () => {
    if (scenario_step === "Scenario_Details") {
      if (scenarioData?.scenario_details?.scenarioName !== '') {
        return true
      } else {
        toast.error('Scenario title is required', { toastId: 's' })
        return false
      }
    } else if (scenario_step === "Orbital_Details") {
      const orbitParams = scenarioData?.orbit_details
      const not_validated = Object.keys(scenarioData?.orbit_details).some(key => {
        if (key === 'altitude') {
          if ((orbitParams?.[key] === '') || +orbitParams?.[key] < 300 || +orbitParams?.[key] > 750) {
            toast.error(orbitParams?.[key] === '' ? capitalizeFirstLetter(key) + " is required" : capitalizeFirstLetter(key) + " should be between 300 and 750 kms", { toastId: "o" })
            return true;
          }
        } else {
          if (orbitParams?.[key] === '') {
            toast.error((capitalizeFirstLetter(key) === "AP" ? "Argument of Periapsis" : capitalizeFirstLetter(key) === "TA" ? "True Anomaly" : capitalizeFirstLetter(key)) + " is required", { toastId: "o" })
            return true;
          }
        }
      });

      if (not_validated) {
        return false
      } else {
        return true
      }
    } else if (scenario_step === 'Ground_Station') {
      if (scenarioData?.ground_stations?.length > 0 && MissionGSList.length > 0) {
        return true
      } else {
        toast.error('Atleast one ground station is required', { toastId: 's' })
        return false
      }
    } else if (scenario_step === 'Schedule') {
      let duration = scenarioData?.duration
      if (duration !== '' && duration >= 10 && duration <= (scenarioResponseData?.['scenarioDuration'])) {
        return true
      } else {
        toast.error(duration === '' ? 'Duration is required' : duration < 10 ? 'Duration should be greater than or equal to 10 minute.' : `Duration should not be greater then 1440 minute.`, { toastId: 's' })
        return false
      }
    }
  }


  const handleModalSectionNext = () => {
    if (scenario_step === "Scenario_Details" && check_validation()) {
      if (scenarioData?.scenario_details?.scenarioName === scenarioResponseData?.scenarioName) {
        setDataSubmited([...dataSubmited, 'Scenario_Details'])
        navigate_scenario("Orbital_Details", scenarioResponseData?.scenarioName, scenarioResponseData?.scenarioId)
        setScenarioScheduleData([])
      } else {
        if (window.location.pathname?.includes('update_scenario')) {
          update_scenario()
        } else {
          create_scenario()
        }
      }
    } else if (scenario_step === "Orbital_Details" && check_validation()) {
      post_orbit_details()
    } else if (scenario_step === "Additional_Orbits") {
      if (addAdditionaOrbitDetails) {
        setAddAdditionaOrbitDetails(false)
      }
      post_external_orbits()
    } else if (scenario_step === "Ground_Station" && check_validation()) {
      post_scenario_ground_stations()
    } else if (scenario_step === "Schedule" && check_validation()) {
      post_scenario_taskplan()
    }
  };

  const handleModalSectionPrevious = () => {
    if (scenario_step === "Orbital_Details") {
      navigate_scenario('Scenario_Details', scenario_name, scenario_id)
    } else if (scenario_step === "Additional_Orbits") {
      calculate_orbital_details(scenarioData)
      navigate_scenario('Orbital_Details', scenario_name, scenario_id)
    } else if (scenario_step === "Ground_Station") {
      if (userData?.['feature-allowed']?.['advancedSimulation'] === 'No') {
        calculate_orbital_details(scenarioData)
      }
      navigate_scenario(userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? 'Additional_Orbits' : 'Orbital_Details', scenario_name, scenario_id)
    } else if (scenario_step === "Schedule") {
      navigate_scenario('Ground_Station', scenario_name, scenario_id)
    }
  };

  const create_scenario = () => {
    setLoading('Creating')
    let body = {
      "scenarioName": scenarioData.scenario_details?.scenarioName.trim(),
      "missionId": mission_id,
      "satId": sat_id,
      "trueTwinId": truetwin_id,
      "sampleRate": scenarioData.scenario_details?.sampleRate,
      "isQuickDeployFlag": 'false',
      "draftMode": scenarioData.scenario_details?.draftMode,
      "status": 'Configure',
      'isScenarioAutomated': 'true',
    }
    MissionService.postScenario(body).then((response) => {
      if (response?.data) {
        let scenarioName = response?.data?.scenarioName
        let scenarioId = response?.data?.scenarioId
        setDataSubmited([...dataSubmited, 'Scenario_Details'])
        navigate_scenario("Orbital_Details", scenarioName, scenarioId)
        setScenarioScheduleData([])
      }
    }).catch((err) => {
      setLoading(false)
      if (err.response?.data === "Scenario with same name already exists") {
        toast.error('The name you entered is already in use for another scenario.', { toastId: 'o' });
      } else {
        toast.error("Error in creating scenario.", { toastId: '0' });
      }
    })
  }

  const update_scenario = () => {
    setLoading('Updating')
    let body = {
      ...scenarioResponseData,
      scenarioName: scenarioData.scenario_details?.scenarioName.trim(),
    }
    MissionService.putScenario(scenario_id, body)?.then((response) => {
      if (response?.data) {
        let scenarioName = body?.scenarioName
        const scenario_data = {
          ...scenarioData,
          scenario_details: {
            ...scenarioData.scenario_details,
            scenarioName: scenarioName,
          }
        }
        get_scenario_data(scenario_id)
        calculate_orbital_details(scenario_data)
        setDataSubmited([...dataSubmited, 'Scenario_Details'])
        setScenarioScheduleData([])
        navigate_scenario('Orbital_Details', scenarioName, scenario_id)
      }
    })?.catch((err) => {
      setLoading(false)
      if (err.response?.data === "Scenario with same name already exists") {
        toast.error('The name you entered is already in use for another scenario.', { toastId: 'o' });
      } else {
        toast.error("Getting error while updating scenario.", { toastId: '0' });
      }
    })
  }

  const checkOrbitDataIsChanged = (orbitData, bodyData) => {
    if ((orbitData?.AP === bodyData?.AP) && (orbitData?.RAAN === bodyData?.RAAN) && (orbitData?.TA === bodyData?.TA) && (orbitData?.altitude === bodyData?.altitude) && (orbitData?.eccentricity === bodyData?.eccentricity) && (orbitData?.epoch === bodyData?.epoch) && (orbitData?.inclination === bodyData?.inclination)) {
      return true
    } else {
      return false
    }
  }

  const post_orbit_details = () => {
    let body = {
      epoch: truncateNumber(Number((new Date(scenarioData?.orbit_details?.epoch).getTime() / 1000)), 0),
      altitude: scenarioData?.orbit_details?.altitude,
      eccentricity: scenarioData?.orbit_details?.eccentricity,
      inclination: scenarioData?.orbit_details?.inclination,
      RAAN: scenarioData?.orbit_details?.RAAN,
      AP: scenarioData?.orbit_details?.AP,
      TA: scenarioData?.orbit_details?.TA
    }
    let scenarioId = scenarioResponseData?.scenarioId

    if (checkOrbitDataIsChanged(scenarioResponseData?.orbit, body)) {
      setScenarioScheduleData([])
      setDataSubmited([...dataSubmited, 'Orbital_Details'])
      navigate_scenario(userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? 'Additional_Orbits' : 'Ground_Station', scenario_name, scenario_id)
    } else {
      setLoading('Plotting')
      MissionService.patchScenario(scenarioId, body).then((response) => {
        if (response?.data) {
          scenario_visualization().init_satellite_packet(viewer, scenarioId, truetwin_id)
          get_scenario_data(scenarioId)
          setDataSubmited([...dataSubmited, 'Orbital_Details'])
          setScenarioScheduleData([])
          navigate_scenario(userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? 'Additional_Orbits' : 'Ground_Station', scenario_name, scenario_id)
        }
      }).catch((err) => {
        setLoading(false)
        toast.error("Getting error while posting scenario orbit details", { toastId: '0' });
      })
    }
  }


  const get_prediction_status = (scenarioId: string) => {
    set_prediction_loading('Analyzing')
    MissionService.getPredictionStatus(scenarioId).then((res) => {
      if (res?.data) {
        postPreSimulation(res?.data)
      }
    }).catch((err) => {
      console.log("Getting error while getting prediction status");
    }).finally(() => {
      set_prediction_loading(false)
    })
  }

  const generate_scenario_schedule = () => {
    set_prediction_data({
      adcs_urls: [],
      data_flow_urls: [],
      power_urls: [],
      has_prediction_data: false,
    })
    let scenarioId = scenarioResponseData?.scenarioId ? scenarioResponseData?.scenarioId : scenario_id
    MissionService.generateSchedule(scenarioId)?.then((response) => {
      if (response?.data) {
        get_scenario_data(scenarioId)
        get_prediction_status(scenarioId)
        setScenarioScheduleData(response?.data?.length > 0 ? response?.data : [])
        setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station', 'Schedule'])
      } else {
        toast.error("No tasks scheduled for this scenario.", { toastId: '0' });
      }

    })?.catch((err) => {
      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
      toast.error("Getting error while generating scenario schedule.", { toastId: '0' });
    }).finally(() => {
      setLoading(false)
    })
  }

  const post_external_orbits = () => {
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName

    setDataSubmited([...dataSubmited, 'Additional_Orbits'])
    setScenarioScheduleData([])
    navigate_scenario('Ground_Station', scenarioName, scenarioId)
  }

  function gsAreIdentical(gs1, gs2) {
    // Check if arrays have the same length
    if (gs1?.length !== gs2?.length) {
      return false;
    }

    // Compare elements one by one
    for (let i = 0; i < gs2?.length; i++) {
      if (gs1[i] !== gs2[i]) {
        return false;
      }
    }
    return true;
  }

  const post_scenario_ground_stations = () => {
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    let body = {
      ...scenarioResponseData,
      "draftMode": 'Yes',
      "status": 'Configure',
      'groundStations': scenarioData?.ground_stations
    }

    let gsIdenticle = gsAreIdentical(scenarioResponseData?.groundStations, body?.groundStations)
    if (!gsIdenticle) {
      setLoading('Updating')
      MissionService?.putScenario(scenarioId, body)?.then((response) => {
        if (response?.data) {
          get_scenario_data(scenarioId)
          setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
          setScenarioScheduleData([])
          navigate_scenario('Schedule', scenarioName, scenarioId)
        }
      })?.catch((err) => {
        toast.error("Getting error while posting ground stations.", { toastId: '0' });
        setLoading(false)
      })
    } else {
      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
      setScenarioScheduleData([])
      navigate_scenario('Schedule', scenarioName, scenarioId)
    }
  }


  const post_scenario_taskplan = () => {
    let scenarioId = scenarioResponseData?.scenarioId
    const simulation_time = scenarioData.start_time;
    const scenario_start_time = scenarioResponseData?.scenarioStartTime * 1000;
    const scenario_end_time = scenario_start_time + (scenarioResponseData.scenarioDuration * 60000)
    const simulation_duration = scenarioData.duration
    const simulation_end_time = simulation_time + (simulation_duration * 60000);

    if ((simulation_time >= scenario_start_time) && (simulation_duration >= 10) && (simulation_end_time <= scenario_end_time)) {
      setLoading('Generating')
      let body = {
        ...scenarioResponseData,
        draftMode: 'No',
        status: 'Run Simulation',
        "isScenarioAutomated": scenarioData?.task_plan === '' ? 'true' : 'false',
        "taskPlanId": scenarioData?.task_plan,
        'simulationDuration': scenarioData?.duration,
        'simulationStartTime': truncateNumber(Number((new Date(scenarioData?.start_time).getTime() / 1000)), 0)
      }

      MissionService?.putScenario(scenarioId, body)?.then((response) => {
        if (response?.data && !importedSchedule?.['schedule_imported']) {
          generate_scenario_schedule()
        } else {
          setLoading(false)
        }
      })?.catch((err) => {
        setLoading(false)
        toast.error("Getting error while posting taskplan details.", { toastId: '0' });
      })
    } else {
      toast.error('Simulation window should be within 24 hr of scenario epoch time', { toastId: "error" });
      setScenarioData({
        ...scenarioData,
        start_time: scenarioResponseData.simulationStartTime * 1000,
        duration: scenarioResponseData.simulationDuration
      })
    }
  }

  const undeploy_truetwin = () => {
    CommandCenterService.unDeployTrueTwin(truetwin_id)
      ?.then((res) => {
        if (res) {
          setDeployLoader({ "error": `An error occurred while deploying ${TrueTwin?.['Category']} ${truetwin_name}.` });
        }
      })
      .catch((err) => {
        setDeployLoader({ "error": `An error occurred while deploying ${TrueTwin?.['Category']} ${truetwin_name}.` });
      });
  };

  const dis_connect_ground_station = () => {
    CommandCenterService.disconnectGroundStation(
      TrueTwin?.["groundStationId"],
      TrueTwin?.["trueTwinId"]
    )
      ?.then((res) => {
        if (res.data) {
          undeploy_truetwin();
        }
      })
      .catch((err) => {
        undeploy_truetwin();
      });
  };

  const run_simulation = (body) => {
    MissionService.runSimulation(body)?.then(res => {
      if (res.data) {
        setDeployLoader({ 'deployed': `${TrueTwin?.['Category']} ${truetwin_name} deployed successfully.` })
      }
    }).catch(err => {
      console.log('something went wrong');
      dis_connect_ground_station()
    })
  }

  const postPreSimulation = (responseData) => {
    let agilityUrl: { id: number, name: string, url: string }[] = [];
    let powerUrl: { id: number, name: string, url: string }[] = [];
    let dataFlowUrl: { id: number, name: string, url: string }[] = [];

    if (responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']) {
      if (responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['adcs']) {
        agilityUrl = responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['adcs']
      }
      if (responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['power']) {
        powerUrl = responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['power']
      }
      if (responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['dataflow']) {
        dataFlowUrl = responseData?.['grafanaData']?.['Urls']?.['SchPredictionData']?.['dataflow']
      }

      const agilityUrlArray = agilityUrl.sort((a, b) => {
        return a.id - b.id;
      });

      const powerUrlArray = powerUrl.sort((a, b) => {
        return a.id - b.id;
      });

      const dataFlow = dataFlowUrl.sort((a, b) => {
        return a.id - b.id;
      });

      set_prediction_data({
        has_prediction_data: true,
        power_urls: powerUrlArray,
        adcs_urls: agilityUrlArray,
        data_flow_urls: dataFlow
      })
    } else {
      set_prediction_data({
        has_prediction_data: false,
        power_urls: [],
        adcs_urls: [],
        data_flow_urls: []
      })
    }
  }

  const deploy_truetwin_by_launch_type = () => {
    setDeployLoader({ 'deploying': `Deploying ${TrueTwin?.['Category']} ${truetwin_name}...` });
    let data = {
      ...TrueTwin,
      deployInfo: {
        'type': scenario_name,
        'scenarioId': scenario_id,
        'scenarioName': scenario_name,
        'isManual': (scenarioResponseData?.['taskPlanId'] && scenarioResponseData?.['taskPlanId'] !== '') ? false : true
      }
    }
    CommandCenterService.deployTrueTwin(truetwin_id, data)?.then((res) => {
      if (res.data) {
        if (TrueTwin?.['Category'] === "TrueTwin") {
          CommandCenterService.connectGroundStation(res.data?.["groundStationId"], truetwin_id, scenario_id)?.then((res) => {
            if (res?.data) {
              if (TrueTwin?.['isRemoteAppConfigured'] === 'No') {
                let body = {
                  "simulationName": "Simultion" + scenario_name,
                  "trueTwinId": truetwin_id,
                  "scenarioId": scenario_id,
                  "missionId": mission_id,
                  "satId": sat_id,
                }
                run_simulation(body)
              } else {
                setDeployLoader({
                  'deployed': `TrueTwin ${truetwin_name} deployed successfully.`,
                  'connecting': `Connecting to remote application...`
                })
              }
            }
          })
            .catch((err) => {
              dis_connect_ground_station()
            });
        } else {
          setDeployLoader({
            'deployed': `FlatSat ${truetwin_name} deployed successfully.`,
            'connect': `Run FlatSat proxy on your setup, port: ${TrueTwin?.['satellitePort']}`
          })
        }
      }
    })?.catch((err) => {
      setDeployLoader({ "error": `An error occurred while deploying TrueTwin ${truetwin_name}.` });
    });
  };

  const upload_generated_schedule = () => {
    setLoading('Uploading')
    CommandCenterService.uploadScenarioSchedule(scenario_id, truetwin_id)?.then(res => {
      if (res.data) {
        toast.success('Schedule uploaded successfully.');
        navigate_to_tt_dashboard()
      }
    }).catch(err => {
      toast.error('Something went wrong while uploading schedule data.');
    }).finally(() => {
      setLoading(false);
    })
  }


  const upload_imported_schedule = () => {
    if (scenario_id) {
      setLoading('Uploading')
      MissionService.postScenarioSchedule(scenario_id, importedSchedule?.['file_data']).then((res) => {
        if (res) {
          toast.success('Schedule uploaded successfully.');
          get_scenario_data(scenario_id)
          get_prediction_status(scenario_id)
          setImportedSchedule({
            ...importedSchedule,
            schedule_upload: true,
            show_deploy_btn: true,
            show_upload_btn: false,
          })
          setUploaddFileStatus("")
        }
      }).catch((err) => {
        setUploaddFileStatus("Error in Upload File")
        setLoading(false)
      })
    }
  }

  const set_simulation_time = (time: number, duration: number) => {
    let start_time = time
    let end_time = time + (duration * 60000)
    setSimulationTime({
      start_time: start_time,
      end_time: end_time,
    })
  }


  useEffect(() => {
    if (scenario_step) {
      let scroll_container: any = scrollRef?.current
      let form_section: any = formSectionRef?.current
      if (scroll_container && form_section) {
        scroll_container.scrollTop = form_section.offsetTop - 40
      }
    }
  }, [scenario_step, openTaskPlanMenu])


  useEffect(() => {
    if (scenario_step) {
      let scroll_container: any = scrollRef?.current
      let form_section: any = formSectionRef?.current
      if (scroll_container && form_section) {
        scroll_container.scrollTop = form_section.offsetTop - 40
      }
    }
  }, [scenario_step, openTaskPlanMenu])

  useEffect(() => {
    if (scenarioScheduleData?.length > 0) {
      set_show_schedule_notification(true)
      setTimeout(() => {
        set_show_schedule_notification(false)
      }, 3000)
    }
  }, [scenarioScheduleData])

  useEffect(() => {
    if (MissionGSList?.length && !scenario_id) {
      const all_ground_stations = MissionGSList.map(gs => gs?.['groundStationId'])
      scenario_visualization().init_gs_packets(viewer, MissionGSList, all_ground_stations)
    }
  }, [MissionGSList, scenario_id])

  return (
    <ViewerContext.Provider value={{ viewer, setViewer, }}>
      <div className="scenario__container__main" onClick={close_unpinned_modal}>
        <div className="scenario__header__btn__container">
          <RightHeaderSection
            open_modal={open_modal}
            close_modal={close_modal}
            modals_details={modalDetails}
            show_schedule_notification={show_schedule_notification}
            prediction_data={prediction_data.has_prediction_data}
            prediction_loading={prediction_loading}
            scenario_scheduled={scenarioScheduleData?.length ? true : false}
          />
        </div>
        <div className="scenario_mainModal" id="planeModal">
          <ModalHeader
            minimize_modal={minimize_modal}
            set_minimize_modal={() => { set_minimize_modal(!minimize_modal) }}
            close={() => { navigate_to_truetwin_details() }}
          />
          {minimize_modal && (
            <Fragment>
              <div className="modal_body_section" ref={scrollRef} >

                <div ref={scenario_step === "Scenario_Details" ? formSectionRef : null} className={scenario_step === "Scenario_Details" ? "highLightedBackground" : ""}>
                  <ScenarioDetails
                    scenario_details={scenarioData.scenario_details}
                    set_scenario_details={(data: object) => setScenarioData({
                      ...scenarioData,
                      scenario_details: {
                        ...scenarioData.scenario_details, ...data
                      }
                    })}
                    navigate_scenario={(data) => {
                      reset_data()
                      navigate_scenario(data, scenario_name, scenario_id)
                    }}
                    data_submited={dataSubmited}
                    true_twin={{}}
                    scenario_step={scenario_step}
                    loading={loading || prediction_loading}
                  />
                </div>

                <div ref={scenario_step === "Orbital_Details" ? formSectionRef : null} className={scenario_step === "Orbital_Details" ? "highLightedBackground" : ""}>
                  <OrbitalDetails
                    orbit_details={scenarioData.orbit_details}
                    set_scenario_orbit_details={(data: object) => {
                      let simulation_start_time = data?.['epoch'] ? new Date(data?.['epoch']).getTime() : new Date(scenarioData?.start_time).getTime()
                      let simulation_duration = scenarioData?.duration
                      set_simulation_time(simulation_start_time, simulation_duration)
                      setScenarioData({
                        ...scenarioData,
                        start_time: simulation_start_time,
                        orbit_details: {
                          ...scenarioData?.orbit_details,
                          ...data,
                          epoch: simulation_start_time,
                        }
                      })
                    }}
                    navigate_scenario={(data) => {
                      reset_data()
                      navigate_scenario(data, scenario_name, scenario_id)
                      calculate_orbital_details(scenarioData)
                    }}
                    data_submited={dataSubmited}
                    true_twin={{}}
                    scenario_step={scenario_step}
                    loading={loading || prediction_loading}
                  />
                </div>

                {userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' &&
                  <div ref={scenario_step === "Additional_Orbits" ? formSectionRef : null}
                    className={scenario_step === "Additional_Orbits" ? `highLightedBackground ${scenarioData.additional_orbits?.length === 0 ? 'pb-0' : ''}` : ""}>
                    <AdditionaOrbitDetails
                      additional_orbit_details={scenarioData.additional_orbit_details}
                      additional_orbits={scenarioData.additional_orbits}
                      set_scenario_additional_orbits={(data: any) => setScenarioData({
                        ...scenarioData,
                        additional_orbits: data
                      })}
                      set_scenario_additional_orbits_details={(data: any) => setScenarioData({
                        ...scenarioData,
                        additional_orbit_details: {
                          ...scenarioData.additional_orbit_details, ...data
                        }
                      })}
                      navigate_scenario={(data) => {
                        reset_data()
                        navigate_scenario(data, scenario_name, scenario_id)
                        calculate_orbital_details(scenarioData)
                      }}
                      data_submited={dataSubmited}
                      true_twin={{}}
                      scenario_step={scenario_step}
                      isOpen={addAdditionaOrbitDetails}
                      open_modal={(data) => { setAddAdditionaOrbitDetails(data) }}
                      close_modal={() => { setAddAdditionaOrbitDetails(false) }}
                      reset_additional_orbits={() => { calculate_orbital_details(scenarioData) }}
                      loading={loading || prediction_loading}
                      scenario_id={scenario_id}
                      scenario_name={scenario_name}
                      setAdditionalOrbits={setAdditionalObjectUpdated}
                      additionalOrbit={additionalObjectUpdated}
                      set_loading={setLoading}
                      scenarioData={scenarioResponseData}
                      get_scenario_data={get_scenario_data}
                    />
                  </div>
                }

                <div ref={scenario_step === "Ground_Station" ? formSectionRef : null} className={scenario_step === "Ground_Station" ? "highLightedBackground" : ""}>
                  <ScenarioGroundStation
                    all_ground_stations={MissionGSList}
                    ground_stations={scenarioData.ground_stations}
                    set_scenario_ground_stations={(data: any) => setScenarioData({
                      ...scenarioData,
                      ground_stations: data
                    })}
                    navigate_scenario={(data) => {
                      reset_data()
                      navigate_scenario(data, scenario_name, scenario_id)
                    }}
                    data_submited={dataSubmited}
                    scenario_step={scenario_step}
                    loading={loading || prediction_loading}
                  />
                </div>

                <div ref={scenario_step === "Schedule" ? formSectionRef : null} className={scenario_step === "Schedule" ? "highLightedBackground" : ""}>
                  <ScenarioSchedule
                    prediction_loading={prediction_loading}
                    setOpenTaskPlanMenu={(data) => { setOpenTaskPlanMenu(data) }}
                    task_plan={scenarioData.task_plan}
                    set_scenario_task_plan={(data: string) => {
                      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
                      setScenarioData({
                        ...scenarioData,
                        task_plan: data
                      })
                      if (importedSchedule?.['schedule_imported']) {
                        setImportedSchedule({
                          ...importedSchedule,
                          schedule_upload: false,
                          show_deploy_btn: false,
                          show_upload_btn: true,
                        })
                      }
                    }}
                    duration={scenarioData.duration}
                    set_simulation_duration={(data: any) => {
                      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
                      if (importedSchedule?.['schedule_imported']) {
                        setImportedSchedule({
                          ...importedSchedule,
                          schedule_upload: false,
                          show_deploy_btn: false,
                          show_upload_btn: true,
                        })
                      }
                      let simulation_start_time = new Date(scenarioData?.start_time).getTime()
                      let simulation_duration = data
                      set_simulation_time(simulation_start_time, simulation_duration)
                      setScenarioData({
                        ...scenarioData,
                        duration: simulation_duration
                      })
                    }}
                    start_time={scenarioData.start_time}
                    set_simulation_start_time={(data: any) => {
                      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
                      if (importedSchedule?.['schedule_imported']) {
                        setImportedSchedule({
                          ...importedSchedule,
                          schedule_upload: false,
                          show_deploy_btn: false,
                          show_upload_btn: true,
                        })
                      }
                      let simulation_start_time = new Date(data).getTime()
                      let simulation_duration = scenarioData?.duration
                      set_simulation_time(simulation_start_time, simulation_duration)
                      setScenarioData({
                        ...scenarioData,
                        start_time: simulation_start_time
                      })
                    }}
                    epoch={scenarioResponseData?.orbit?.epoch * 1000}
                    scenario_duration={scenarioResponseData?.['scenarioDuration']}
                    navigate_scenario={(data) => { navigate_scenario(data, scenario_name, scenario_id) }}
                    data_submited={dataSubmited}
                    scenario_step={scenario_step}
                    set_imported_schedule={(schedule) => {
                      setImportedSchedule(schedule)
                    }}
                    imported_schedule_data={importedSchedule}
                    uploadFileStatus={uploadFileStatus}
                    setUploaddFileStatus={setUploaddFileStatus}
                    set_scenario_schedule_data={(data => {
                      setScenarioScheduleData(data)
                      set_prediction_data({
                        adcs_urls: [],
                        data_flow_urls: [],
                        power_urls: [],
                        has_prediction_data: false,
                      })
                    })}
                    loading={loading || prediction_loading}
                  />
                </div>
              </div>

              <div className="modalFooter">
                <button
                  disabled={loading ? true : false}
                  className="back_close_btn"
                  onClick={() => {
                    if (scenario_step === "Scenario_Details") {
                      navigate_to_truetwin_details()
                    } else {
                      handleModalSectionPrevious()
                    }
                  }}
                >
                  {scenario_step === "Scenario_Details" ? 'Close' : 'Back'}
                </button>
                <div className="d-flex gap-2">
                  {((!importedSchedule?.['show_upload_btn'] && !importedSchedule?.['show_deploy_btn']) || scenario_step !== "Schedule") &&
                    <Fragment>
                      {(scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && TrueTwin?.['isDeployed'] === 'No') &&
                        <button disabled={loading ? true : false} className="save_close_btn " onClick={navigate_to_truetwin_details}>
                          Save & Close
                        </button>
                      }
                      <button disabled={loading ? true : false} className="save_btn align-items-center gap-1 d-flex" onClick={() => {
                        if (scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && scenarioScheduleData?.length > 0) {
                          if (TrueTwin?.['isDeployed'] === 'No') {
                            deploy_truetwin_by_launch_type()
                          } else if (TrueTwin?.['isDeployed'] === 'Yes' && TrueTwin?.['Category'] === 'FlatSat') {
                            upload_generated_schedule()
                          } else {
                            navigate_to_truetwin_details()
                          }
                        } else {
                          handleModalSectionNext()
                        }
                      }}>
                        {loading ? loading : (scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && scenarioScheduleData?.length > 0) ? TrueTwin?.['isDeployed'] === 'No' ? 'Deploy' : TrueTwin?.['Category'] === 'FlatSat' ? 'Upload Schedule' : 'Close' : 'Next'}{loading && <CircularProgress size={10} className=" text-white" />}
                      </button>
                    </Fragment>
                  }
                  {(importedSchedule?.['show_upload_btn'] || importedSchedule?.['show_deploy_btn']) && scenario_step === 'Schedule' &&
                    <Fragment>
                      <button disabled={loading ? true : false} className="save_close_btn " onClick={navigate_to_truetwin_details}>
                        Save & Close
                      </button>
                      {TrueTwin?.['isDeployed'] === 'No' &&
                        <button className="save_btn"
                          disabled={loading ? true : false}
                          onClick={() => {
                            if (importedSchedule?.['show_deploy_btn']) {
                              deploy_truetwin_by_launch_type()
                            } else {
                              upload_imported_schedule()
                            }
                          }}
                        >
                          {loading ? loading : importedSchedule?.['show_deploy_btn'] ? 'Deploy' : 'Upload'} {loading && <CircularProgress size={10} className=" text-white" />}
                        </button>
                      }

                      {TrueTwin?.['isDeployed'] === 'Yes' && TrueTwin?.['Category'] === 'FlatSat' &&
                        <button className="save_btn"
                          disabled={loading ? true : false}
                          onClick={() => {
                            if (importedSchedule?.['show_deploy_btn']) {
                              deploy_truetwin_by_launch_type()
                            } else {
                              upload_imported_schedule()
                            }
                          }}
                        >
                          {loading ? loading : importedSchedule?.['show_deploy_btn'] ? 'Deploy' : 'Upload'} {loading && <CircularProgress size={10} className=" text-white" />}
                        </button>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            </Fragment>

          )}
        </div>
        <VisualizationWrapper
          scenario_duration={scenarioResponseData?.['scenarioDuration']}
          scenario_start_time={scenarioResponseData?.orbit?.epoch * 1000}
          simulation_start_time={simulationTime?.start_time}
          simulation_end_time={simulationTime?.end_time}
          set_simulation_time={(data: { start_time: number, end_time: number }) => {
            let duration = Math.round((data.end_time - data.start_time) / 60000);
            setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Additional_Orbits', 'Ground_Station'])
            setScenarioData({
              ...scenarioData,
              start_time: data?.['start_time'],
              duration: duration
            })
            setSimulationTime(data)
          }}
          scenario_step={scenario_step}
          open_schedule_modal={() => {
            if (modalDetails?.['schedule']?.['open']) {
              close_modal('schedule')
            } else {
              open_modal('schedule')
            }
          }}
          schedule_data={scenarioScheduleData}
          duration={scenarioData.duration}
          all_ground_stations={MissionGSList}
          scenarioData={scenarioData}
          active_schedule_modal={modalDetails?.['schedule']?.['open'] || show_schedule_notification}
          loading={loading || prediction_loading}
        />
        <ScenarioScheduleModal
          simulation_start_time={scenarioData?.start_time}
          simulation_duration={scenarioData.duration}
          schedule_data={scenarioScheduleData}
          pinned={modalDetails?.['schedule']?.['pinned']}
          open={modalDetails?.['schedule']?.['open']}
          current_modal={modalDetails?.['schedule']?.['current_modal']}
          close_modal={close_modal}
          pinned_modal={pinned_modal}
          set_expanded={set_expanded}
          set_top_modal={set_top_modal}
          expanded={modalDetails?.['schedule']?.['expanded']}
          zIndex={modalDetails?.['schedule']?.['zIndex']}
          modalRef={modalRef}
        />
        <PowerGraphModal
          pinned={modalDetails?.['power']?.['pinned']}
          open={modalDetails?.['power']?.['open']}
          current_modal={modalDetails?.['power']?.['current_modal']}
          close_modal={close_modal}
          pinned_modal={pinned_modal}
          set_expanded={set_expanded}
          set_top_modal={set_top_modal}
          expanded={modalDetails?.['power']?.['expanded']}
          zIndex={modalDetails?.['power']?.['zIndex']}
          powerAnalysisGraphUrl={prediction_data.power_urls}
          modalRef={modalRef}
        />
        <ADCSGraphModal
          pinned={modalDetails?.['adcs']?.['pinned']}
          open={modalDetails?.['adcs']?.['open']}
          current_modal={modalDetails?.['adcs']?.['current_modal']}
          close_modal={close_modal}
          pinned_modal={pinned_modal}
          set_expanded={set_expanded}
          set_top_modal={set_top_modal}
          expanded={modalDetails?.['adcs']?.['expanded']}
          zIndex={modalDetails?.['adcs']?.['zIndex']}
          adcsAgilityGraphUrl={prediction_data.adcs_urls}
          modalRef={modalRef}
        />
        <DataFlowGraphModal
          pinned={modalDetails?.['data_flow']?.['pinned']}
          open={modalDetails?.['data_flow']?.['open']}
          current_modal={modalDetails?.['data_flow']?.['current_modal']}
          close_modal={close_modal}
          pinned_modal={pinned_modal}
          set_expanded={set_expanded}
          set_top_modal={set_top_modal}
          expanded={modalDetails?.['data_flow']?.['expanded']}
          zIndex={modalDetails?.['data_flow']?.['zIndex']}
          dataFlowGraphUrl={prediction_data.data_flow_urls}
          modalRef={modalRef}
        />
        {deployLoader && (
          <LoaderAnimation
            objectName={(truetwin_name && truetwin_name?.length > 10) ? truetwin_name?.substring(0, 10) + '...' : truetwin_name}
            close={() => {
              get_truetwin_details()
              setDeployLoader(false);
            }}
            state={deployLoader}
            truetwin_id={TrueTwin?.['trueTwinId']}
            category={TrueTwin?.['Category']}
            mission_name={mission_name}
            mission_id={mission_id}
            sat_name={sat_name}
            sat_id={sat_id}
            dis_connect_ground_station={dis_connect_ground_station}
          />
        )}


        <ToastContainer position={'top-center'} autoClose={3000} hideProgressBar={true} newestOnTop={true} className='toast-container' toastClassName="custom-toast" closeButton={false} />
      </div>
    </ViewerContext.Provider>
  );
};

export default NewScenarioFlow;
